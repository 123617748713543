body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dailer input {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.btnHover:hover {
  color: #131b47 !important;
  background-color: white !important;
  border-color: #131b47 !important;
}

.btnHover:focus {
  color: #131b47 !important;
  background-color: white !important;
  border-color: #131b47 !important;
}

.btnPrimary {
  color: #131b47 !important;
  background-color: #fff !important;
  border-color: #131b47 !important;
}

.btnSecondary {
  color: #fff !important;
  background-color: #4ac367 !important;
  border-color: #fff !important;
}

.whiteLoader > .ant-spin-dot > .ant-spin-dot-item {
  background-color: #fff;
}

.whiteLoader > .ant-spin-text {
  margin-left: 8px;
}

.mainTabBar > .ant-tabs-nav {
  margin: 0;
}

.mainTabBar-w100 > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;
}

.tabContainer {
  height: calc(100vh - 112px);
}

.dflex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

@font-face {
  font-family: "fontello";
  src: url("fonts/fontello.eot");
  src: url("fonts/fontello.eot") format("embedded-opentype"),
    url("fonts/fontello.woff2") format("woff2"),
    url("fonts/fontello.woff") format("woff"),
    url("fonts/fontello.ttf") format("truetype"),
    url("fonts/fontello.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?79301780#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-activity:before {
  content: "\e800";
} /* '' */
.icon-add-details:before {
  content: "\e801";
} /* '' */
.icon-add-lead:before {
  content: "\e802";
} /* '' */
.icon-add-more:before {
  content: "\e803";
} /* '' */
.icon-add-office:before {
  content: "\e804";
} /* '' */
.icon-add-recruitee:before {
  content: "\e805";
} /* '' */
.icon-add-task:before {
  content: "\e806";
} /* '' */
.icon-add-territory:before {
  content: "\e807";
} /* '' */
.icon-add-user:before {
  content: "\e808";
} /* '' */
.icon-address:before {
  content: "\e809";
} /* '' */
.icon-admin:before {
  content: "\e80a";
} /* '' */
.icon-analytics:before {
  content: "\e80b";
} /* '' */
.icon-appointments:before {
  content: "\e80c";
} /* '' */
.icon-archive:before {
  content: "\e80d";
} /* '' */
.icon-arrow-down:before {
  content: "\e80e";
} /* '' */
.icon-arrow-left:before {
  content: "\e80f";
} /* '' */
.icon-arrow-right:before {
  content: "\e810";
} /* '' */
.icon-attachment:before {
  content: "\e811";
} /* '' */
.icon-back:before {
  content: "\e812";
} /* '' */
.icon-baths:before {
  content: "\e813";
} /* '' */
.icon-beds:before {
  content: "\e814";
} /* '' */
.icon-buyer-consultation:before {
  content: "\e815";
} /* '' */
.icon-buyer:before {
  content: "\e816";
} /* '' */
.icon-calendar-log:before {
  content: "\e817";
} /* '' */
.icon-calendar:before {
  content: "\e818";
} /* '' */
.icon-call-answered:before {
  content: "\e819";
} /* '' */
.icon-call-end:before {
  content: "\e81a";
} /* '' */
.icon-call-forward:before {
  content: "\e81b";
} /* '' */
.icon-call-hold:before {
  content: "\e81c";
} /* '' */
.icon-call-log:before {
  content: "\e81d";
} /* '' */
.icon-call:before {
  content: "\e81e";
} /* '' */
.icon-cancel:before {
  content: "\e81f";
} /* '' */
.icon-check-mark:before {
  content: "\e820";
} /* '' */
.icon-checkbox-normal:before {
  content: "\e821";
} /* '' */
.icon-checkbox-selected:before {
  content: "\e822";
} /* '' */
.icon-client-information:before {
  content: "\e823";
} /* '' */
.icon-collapse:before {
  content: "\e824";
} /* '' */
.icon-column-filter:before {
  content: "\e825";
} /* '' */
.icon-column-sorter:before {
  content: "\e826";
} /* '' */
.icon-compact:before {
  content: "\e827";
} /* '' */
.icon-compare-properties:before {
  content: "\e828";
} /* '' */
.icon-connected-lead:before {
  content: "\e829";
} /* '' */
.icon-create-task:before {
  content: "\e82a";
} /* '' */
.icon-day-view:before {
  content: "\e82b";
} /* '' */
.icon-dead-lead-reason:before {
  content: "\e82c";
} /* '' */
.icon-deals:before {
  content: "\e82d";
} /* '' */
.icon-delete:before {
  content: "\e82e";
} /* '' */
.icon-dial-pad:before {
  content: "\e82f";
} /* '' */
.icon-download-apps:before {
  content: "\e830";
} /* '' */
.icon-download:before {
  content: "\e831";
} /* '' */
.icon-dropdown-arrow:before {
  content: "\e832";
} /* '' */
.icon-edit:before {
  content: "\e833";
} /* '' */
.icon-email-signature:before {
  content: "\e834";
} /* '' */
.icon-email-trail:before {
  content: "\e835";
} /* '' */
.icon-email:before {
  content: "\e836";
} /* '' */
.icon-emoji:before {
  content: "\e837";
} /* '' */
.icon-expand:before {
  content: "\e838";
} /* '' */
.icon-export-data:before {
  content: "\e839";
} /* '' */
.icon-favourite:before {
  content: "\e83a";
} /* '' */
.icon-files:before {
  content: "\e83b";
} /* '' */
.icon-fill-call-answered:before {
  content: "\e83c";
} /* '' */
.icon-fill-call-end:before {
  content: "\e83d";
} /* '' */
.icon-fill-call-forward:before {
  content: "\e83e";
} /* '' */
.icon-fill-call-hold:before {
  content: "\e83f";
} /* '' */
.icon-fill-call-log:before {
  content: "\e840";
} /* '' */
.icon-fill-fake-number:before {
  content: "\e841";
} /* '' */
.icon-fill-incoming:before {
  content: "\e842";
} /* '' */
.icon-fill-mic-off:before {
  content: "\e843";
} /* '' */
.icon-fill-mic-on:before {
  content: "\e844";
} /* '' */
.icon-fill-missed-call:before {
  content: "\e845";
} /* '' */
.icon-fill-outgoing-:before {
  content: "\e846";
} /* '' */
.icon-filter-filled:before {
  content: "\e847";
} /* '' */
.icon-filter-menu:before {
  content: "\e848";
} /* '' */
.icon-filters:before {
  content: "\e849";
} /* '' */
.icon-forward:before {
  content: "\e84a";
} /* '' */
.icon-image:before {
  content: "\e84b";
} /* '' */
.icon-important-notes:before {
  content: "\e84c";
} /* '' */
.icon-inbox:before {
  content: "\e84d";
} /* '' */
.icon-incoming-call:before {
  content: "\e84e";
} /* '' */
.icon-last-inquiry:before {
  content: "\e84f";
} /* '' */
.icon-lead-added:before {
  content: "\e850";
} /* '' */
.icon-lead-assigned:before {
  content: "\e851";
} /* '' */
.icon-lead-relationship:before {
  content: "\e852";
} /* '' */
.icon-lead-stage-down:before {
  content: "\e853";
} /* '' */
.icon-lead-stage:before {
  content: "\e854";
} /* '' */
.icon-lead-star:before {
  content: "\e855";
} /* '' */
.icon-leads:before {
  content: "\e856";
} /* '' */
.icon-listings:before {
  content: "\e857";
} /* '' */
.icon-log-email:before {
  content: "\e858";
} /* '' */
.icon-mail-recieve:before {
  content: "\e859";
} /* '' */
.icon-mail-sent:before {
  content: "\e85a";
} /* '' */
.icon-maximize:before {
  content: "\e85b";
} /* '' */
.icon-menu-open:before {
  content: "\e85c";
} /* '' */
.icon-menu:before {
  content: "\e85d";
} /* '' */
.icon-minimize:before {
  content: "\e85e";
} /* '' */
.icon-missed-call:before {
  content: "\e85f";
} /* '' */
.icon-missing-info:before {
  content: "\e860";
} /* '' */
.icon-month-view:before {
  content: "\e861";
} /* '' */
.icon-more-app:before {
  content: "\e862";
} /* '' */
.icon-more:before {
  content: "\e863";
} /* '' */
.icon-mouse-click:before {
  content: "\e864";
} /* '' */
.icon-my-devices:before {
  content: "\e865";
} /* '' */
.icon-new-dialer:before {
  content: "\e866";
} /* '' */
.icon-no-volume:before {
  content: "\e867";
} /* '' */
.icon-normal-mic-off:before {
  content: "\e868";
} /* '' */
.icon-normal-mic-on:before {
  content: "\e869";
} /* '' */
.icon-notes:before {
  content: "\e86a";
} /* '' */
.icon-notification-clear:before {
  content: "\e86b";
} /* '' */
.icon-notifications:before {
  content: "\e86c";
} /* '' */
.icon-offers-written:before {
  content: "\e86d";
} /* '' */
.icon-outgoing-call:before {
  content: "\e86e";
} /* '' */
.icon-pause:before {
  content: "\e86f";
} /* '' */
.icon-performance:before {
  content: "\e870";
} /* '' */
.icon-pin:before {
  content: "\e871";
} /* '' */
.icon-play:before {
  content: "\e872";
} /* '' */
.icon-pqq:before {
  content: "\e873";
} /* '' */
.icon-price-and-tax:before {
  content: "\e874";
} /* '' */
.icon-print-all:before {
  content: "\e875";
} /* '' */
.icon-privacy-policy:before {
  content: "\e876";
} /* '' */
.icon-radio-normal:before {
  content: "\e877";
} /* '' */
.icon-radio-selected:before {
  content: "\e878";
} /* '' */
.icon-realtor-details:before {
  content: "\e879";
} /* '' */
.icon-recently-viewed:before {
  content: "\e87a";
} /* '' */
.icon-recording:before {
  content: "\e87b";
} /* '' */
.icon-refresh:before {
  content: "\e87c";
} /* '' */
.icon-remove-filter:before {
  content: "\e87d";
} /* '' */
.icon-reply-all:before {
  content: "\e87e";
} /* '' */
.icon-reply:before {
  content: "\e87f";
} /* '' */
.icon-reporting:before {
  content: "\e880";
} /* '' */
.icon-saved-search:before {
  content: "\e881";
} /* '' */
.icon-schedule-send:before {
  content: "\e882";
} /* '' */
.icon-search:before {
  content: "\e883";
} /* '' */
.icon-send:before {
  content: "\e884";
} /* '' */
.icon-settings:before {
  content: "\e885";
} /* '' */
.icon-showing:before {
  content: "\e886";
} /* '' */
.icon-sold:before {
  content: "\e887";
} /* '' */
.icon-sort:before {
  content: "\e888";
} /* '' */
.icon-sqft:before {
  content: "\e889";
} /* '' */
.icon-tasks:before {
  content: "\e88a";
} /* '' */
.icon-text-message:before {
  content: "\e88b";
} /* '' */
.icon-time-picker:before {
  content: "\e88c";
} /* '' */
.icon-to-do-tasks:before {
  content: "\e88d";
} /* '' */
.icon-total-time:before {
  content: "\e88e";
} /* '' */
.icon-transcription:before {
  content: "\e88f";
} /* '' */
.icon-transfer-lead:before {
  content: "\e890";
} /* '' */
.icon-unpin:before {
  content: "\e891";
} /* '' */
.icon-upload:before {
  content: "\e892";
} /* '' */
.icon-verified:before {
  content: "\e893";
} /* '' */
.icon-viewed-pages:before {
  content: "\e894";
} /* '' */
.icon-week-view:before {
  content: "\e895";
} /* '' */
.icon-volume:before {
  content: "\e896";
} /* '' */
.icon-backspace:before {
  content: "\e897";
} /* '' */
